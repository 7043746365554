import CryptoJS from 'crypto-js';

/**
 * AES加密处理（ECB模式）
 */
export function encryptECB (plaintText) {
    let Text = plaintText;
    let options = {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    };
    let key = CryptoJS.enc.Utf8.parse("SXSMYL1234567890"); //密钥
    let encryptedData = CryptoJS.AES.encrypt(Text, key, options);
    let encryptedBase64Str = encryptedData.toString().replace(/\//g, "_");
    encryptedBase64Str = encryptedBase64Str.replace(/\+/g,"-");
    return encryptedBase64Str;
}
