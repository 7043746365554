<template>
  <el-dialog
      v-model="dialogVisible"
      :title="$t('body.share')"
      width="600px"
  >
    <vue-qr
      :text="path"
      :size="200"
      :margin="10"
      :color-dark="'#000000'"
      :color-light="'#ffffff'"
      :logo="require('@/assets/img/SM-蓝黄.png')"
      :logo-scale="0.2">
    </vue-qr>
    <el-input v-model="path" disabled></el-input>
    <el-button @click="copyLink(path)">{{ $t('body.ctrl_c') }}</el-button>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('body.close') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
export default {
  data() {
    return {
      dialogVisible: false,
      path: ''
    }
  },
  methods: {
    share (e) {
      this.path = e
      this.dialogVisible = true
    },
    copyLink (link) {
      if (navigator.clipboard && navigator.clipboard.writeText){
        navigator.clipboard.writeText(link)
            .then(() => {
              this.$message.success($t('body.copy_success'))
            })
            .catch(err => {
              this.$message.error($t('body.copy_fail'))
            });
      } else {
        this.fallbackCopyTextToClipboard(link)
      }
    },
    fallbackCopyTextToClipboard (text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      textArea.style.position = 'fixed'
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.width = '2em'
      textArea.style.height = '2em'
      textArea.style.padding = '0'
      textArea.style.border = 'none'
      textArea.style.outline = 'none'
      textArea.style.boxShadow = 'none'
      textArea.style.background = 'transparent'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()
      try {
        document.execCommand('copy')
        this.$message.success($t('body.copy_success'))
      } catch (err) {
        this.$message.error($t('body.copy_fail'))
      }
      document.body.removeChild(textArea)
    },
  },
  components: {
    vueQr
  },
  mounted() {
    this.eventBus.on('share', this.share)
  }
}
</script>

<style scoped>

</style>
