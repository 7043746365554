export default {
    system: '颂明医疗数字病理云空间',
    body: {
        share: '分享',
        share_click: '点击分享',
        ctrl_c: '复制',
        close: '关闭',
        copy_success: '复制成功',
        copy_fail: '复制失败',
        error: '切片资源已损坏'
    },
    setting: {
        title: '设置主目录',
        Current_directory: '当前主目录',
        set: '设为主目录',
        null: '暂无子目录'
    }
}
