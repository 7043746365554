<template>
  <el-dialog
      v-model="dialogVisible"
      :title="($t('setting.title'))"
      width="600px"
      @open="getPath"
  >
    <p>{{ $t('setting.Current_directory') }}：{{current_path}}</p>
    <el-tree
        lazy
        :empty-text="($t('setting.null'))"
        style="max-width: 600px"
        :highlight-current="true"
        :props="defaultProps"
        :load="loadNode"
        @node-click="handleNodeClick"
        :render-content="renderContent"
    ></el-tree>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="setPath">{{ $t('setting.set') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import folder from "@/assets/img/dir.png";

export default {
  data () {
    return {
      dialogVisible: false,
      current_path: '',
      path: '',
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isFile'
      },
    }
  },
  components: {

  },
  methods: {
    setting() {
      this.dialogVisible = true
    },
    loadNode (node, resolve) {
      if (node.level === 0) {
        this.axios.get('/service/getFilePath?path=' + this.path + '&type=0').then(res => {
          this.treeData = JSON.parse(res.data)
          return resolve(this.treeData)
        })
      } else {
        this.axios.get('/service/getFilePath?path=' + node.data.fullName + '&type=0').then(res => {
          if (res.data.length === 0) {
            return resolve([])
          } else {
            let d = JSON.parse(res.data)
            let data = d.children
            return resolve(data)
          }
        })
      }
    },
    handleNodeClick(e) {
      this.path = e.fullName
    },
    setPath() {
      localStorage.setItem('path', this.path)
      this.dialogVisible = false
    },
    getPath() {
      this.current_path = localStorage.getItem('path')
    },
    renderContent(h,{ node, data }) {
      return h('span', [
        h('img', {
          src: folder, // 使用本地图标资源
          style: { width: '20px', height: '20px', marginRight: '5px' },
        }),
        h('span', { style: { marginLeft: '5px' } }, data.name), // 标签内容
      ]);
    },
  },
  mounted() {
    this.eventBus.on('setting', this.setting)
  }
}
</script>

<style scoped>

</style>
