<template>
  <div class="header">
    <el-row>
      <el-col :span="4">
        <div class="logo_background">
          &nbsp;<img class="logo" src="../assets/img/SM-蓝黄.png" alt="">
          <span class="name">&nbsp;{{ $t('system') }}</span>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="head">
          <el-col :span="3">
<!--            <el-dropdown>
              <span class="el-dropdown-link">
                系统
                <el-icon class="el-icon&#45;&#45;right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="category_manage()">类别管理</el-dropdown-item>
                  <el-dropdown-item @click="user_manage()">用户管理</el-dropdown-item>
                  <el-dropdown-item @click="permission_manage()">权限管理</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>-->
          </el-col>
          <el-col :span="16"></el-col>
          <el-col class="language_change" @click=language_change :xs="5" :sm="3" :md="2">
            <span>中/EN</span>
          </el-col>
<!--          <el-col :span="1" style="cursor: pointer">
&lt;!&ndash;            <el-avatar @click="popover_click" class="out" :src=portrait />&ndash;&gt;
            <el-icon size="24" color="white" @click="openSetting"><setting/></el-icon>
          </el-col>-->
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import portrait from '../assets/img/portrait.jpg'
import {Setting} from "@element-plus/icons-vue";
</script>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    category_manage(){
      this.eventBus.emit('catagory_dialog')
    },
    user_manage() {

    },
    permission_manage() {

    },
    popover_click() {
      this.eventBus.emit('popover_show')
    },
    openSetting() {
      this.eventBus.emit('setting')
    },
    language_change() {
      if (this.$i18n.locale == 'cn') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'cn'
      }
    }
  }
}
</script>

<script setup>

</script>

<style>
.logo{
  width: 48px;
  height: 24px;
  color: white;
  transform: translateY(25%);
}
.logo_background{
  width: 100%;
  background-color: #0f97c0;
  color: white;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
}
.header{
  height: 60px;
  background-color: #3ea7c5;
}
.header svg{
  transform: translateY(25%);
  width: 40px;
}
.head{
  display: flex;
  height: 60px;
  line-height: 60px;
}
.head .el-dropdown{
  color: #ffffff;
  font-size: 18px;
  line-height: 60px;
}
.head .out{
  margin-top: 10px;
  line-height: 60px;
  cursor: pointer;
}
.language_change{
  width: 100px;
  text-align: center;
  color: #FFFFFF;
  font-size: 20px;
}
.language_change:hover{
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>
<!--<template>
  <div>
    <div class="header">
      <el-row align="middle">
        <img class="logo" src="../assets/img/SM-蓝黄.png" alt="">
        <p>颂明医疗数字切片库</p>
      </el-row>
    </div>
  </div>
</template>

<script>

</script>

<style scoped lang="scss">
  .header{
    width: 100%;
    height: 100px;
    background-color: #5bc0de;
  }
  .header p{
    padding-left: 10px;
    margin: 0px;
    color: white;
    line-height: 100px;
    font-weight: bold;
    font-family: '宋体';
  }
  .logo{
    margin-left: 20px;
    line-height: 100px;
  }
</style>-->
