import { createI18n } from 'vue-i18n';
import en from "./en.js"
import cn from "./cn.js"

const messages = {
  en,
  cn
};

const i18n = createI18n({
    locale: 'cn', //默认当前语言
    fallbackLocale: 'en', //替补语言，比如上边locale语言显示不了了就显示这个语言
    messages, //所有语言
    legacy:false, //用于区分是否使用组合式api，v3使用要设为false
})

export default i18n;
