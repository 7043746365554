<template>
  <el-dialog
      v-model="dialogVisible"
      title="类别管理"
      width="500"
      :before-close="handleClose"
  >
    <el-tree
        lazy
        style="max-width: 600px"
        :highlight-current="true"
        :data="treeData"
        :props="defaultProps"
        :load="loadNode"
        @node-click="handleNodeClick"
    ></el-tree>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  data() {
    return {
      dialogVisible: false,
      treeData: '',
      defaultProps: {
        children: 'child',
        label: 'name',
        isLeaf: 'leaf'
      },
    }
  },
  methods: {
    opendialog() {
      this.axios.post('/api/category/category_list').then(res => {
        this.treeData = res.data.data
      })
      this.dialogVisible = true
    },
    handleClose(done) {
      done()
    }
  },
  mounted() {
    this.eventBus.on('catagory_dialog', this.opendialog)
  },
  unmounted() {
    this.eventBus.off('catagory_dialog', this.opendialog)
  }
})
</script>

<style scoped>

</style>
