export default {
    system: 'SongMingSlideManage',
    body: {
        share: 'share',
        share_click: 'Click to share',
        ctrl_c: 'Click to copy',
        close: 'close',
        copy_success: 'Copy Success',
        copy_fail: 'Copy Failed',
        error: 'Sliced resources are corrupted'
    },
    setting: {
        title: 'settings',
        Current_directory: 'Current home directory',
        set: 'Set as main catalogue',
        null: 'No subcategories'
    }
}
