import { createRouter, createWebHashHistory  } from 'vue-router'
// import Login from "../views/Login.vue"
import Index from '../views/Index.vue'
import Detail from "../views/detail.vue";
const routes = [
  /*{
    path: '/',
    name: 'Login',
    component: Login
  },*/
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
