<template>
  <upper></upper>
  <div class="body">
    <div class="main">
      <el-row align="middle">
          <svg width="2rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" data-v-ea893728=""><path fill="currentColor" d="M160 448a32 32 0 0 1-32-32V160.064a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V416a32 32 0 0 1-32 32zm448 0a32 32 0 0 1-32-32V160.064a32 32 0 0 1 32-32h255.936a32 32 0 0 1 32 32V416a32 32 0 0 1-32 32zM160 896a32 32 0 0 1-32-32V608a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32v256a32 32 0 0 1-32 32zm448 0a32 32 0 0 1-32-32V608a32 32 0 0 1 32-32h255.936a32 32 0 0 1 32 32v256a32 32 0 0 1-32 32z"></path></svg>
          <p class="title_one">病例详情</p>
      </el-row>
      <hr style="border: solid 2px #46b8da">
      <div class="content">
        <el-row>
          <el-col :span="12">
            <p><span>性别</span>&emsp;{{ list.sex }}</p>
          </el-col>
          <el-col class="info" :span="12">
            <p><span>年龄</span>&emsp;{{ list.age }}</p>
          </el-col>
        </el-row>
        <hr>
        <el-row>
          <el-col :span="3">
            <p><span>临床资料</span></p>
          </el-col>
          <el-col style="padding-top: 16px" :span="21">
            <el-input
                v-model="list.desc_content"
                :rows="10"
                class="profile"
                type="textarea"
                disabled
            />
          </el-col>
        </el-row>
        <hr>
        <el-row>
          <el-col :span="3">
            <p><span>数字切片</span></p>
          </el-col>
          <el-col :span="21">
            <div class="slice">
              <img @click="browse" class="thumbnail" :src="`/slide/Image/Thumbnail?path=${list.section_path}`">
            </div>
          </el-col>
        </el-row>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import upper from '../components/Header.vue'
export default {
  data () {
    return {
      list:[]
    }
  },
  methods: {
    browse(){
      window.open(`slideViewer/#/viewer?url=${this.list.section_path}&type=0`)
    }
  },
  components: {
    upper,
  },
  mounted() {
    this.axios.get('/api/get_sections_detail?id=' + this.$route.query.params).then(res => {
      this.list = res.data.data
      let newUrl = this.list.section_path.replace(/ /g, '+');
      newUrl = newUrl.replace(/^https:/, 'http:');
      this.list.section_path=encodeURIComponent(newUrl);
      if (res.data.data.sex == 1) {
        this.list.sex = '男'
      } else {
        this.list.sex = '女'
      }
    })
  }
}
</script>

<style scoped>
  .body{
    height: 811px;
    background-color: #f5f5f5;
  }
  p{
    padding-left: 16px;
  }
  .main{
    background-color: white;
    text-align: left;
    margin: 0 auto;
  }
  .content{
    padding: 5px;
    border: solid 1px #f5f5f5;
  }
  .thumbnail{
    width: 192px;
    height: 150px;
    padding: 9px;
    border: 1px solid #dddddd;
    border-radius: 15px;
  }
  .info{
    text-align: left;
  }
  .title_one{
    color: #428bca;
    margin: 0;
    line-height: 40.91px;
    font-size: 20px;
  }
  .thumbnail:hover{
    cursor: pointer;
  }
  .content p{
    font-weight: 600;
  }
  .profile{
    width: 100%;
  }
</style>
