import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: '',
  },
  getters: {
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: 'webInfo'
    })
  ]
})
