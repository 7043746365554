<template>
  <upper></upper>
  <el-row>
    <el-col :md="4" :sm="aside_sm" :xs="aside_xs">
      <div class="aside">
<!--        <el-input
            v-model="input3"
            style="max-width: 600px"
            placeholder="Please input"
            class="input-with-select"
        >
          <template #append>
            <el-button><el-icon size="16"><search /></el-icon>
            </el-button>
          </template>
        </el-input>-->
        <el-collapse @change="getDirectory">
          <el-collapse-item name="1">
            <template #title>
              <span>&nbsp;&nbsp;</span>
              <el-icon size="18" @click="refresh"><Refresh /></el-icon>
              <span>&nbsp;&nbsp;</span>
              <el-icon size="16"><Folder></Folder></el-icon>
              <span>&nbsp;Slide Library</span>
            </template>
            <el-tree
                v-if="tree"
                lazy
                :empty-text="($t('setting.null'))"
                style="max-width: 600px"
                :highlight-current="true"
                :props="defaultProps"
                :load="loadNode"
                @node-click="handleNodeClick"
                :render-content="renderContent"
            ></el-tree>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="aside_mobile">
        <div class="folder_icon" @click="expand">
          <el-popover
              v-model:visible="visible"
              trigger="manual"
              placement="bottom"
              :width="120"
              content="点击可选择目录"
          >
            <template #reference>
              <el-icon color="#FFF" size="28"><Folder /></el-icon>
            </template>
          </el-popover>
        </div>
        <el-drawer
            ref="drawerRef"
            v-model="dialog"
            title=""
            :before-close="handleClose"
            direction="ltr"
            size="55%"
            custom-class="demo-drawer"
        >
          <div class="demo-drawer__content">
            <el-collapse @change="getDirectory">
              <el-collapse-item name="1">
                <template #title>
                  <span>&nbsp;&nbsp;</span>
                  <el-icon size="18" @click="refresh"><Refresh /></el-icon>
                  <span>&nbsp;&nbsp;</span>
                  <el-icon size="16"><Folder></Folder></el-icon>
                  <span>&nbsp;Slide Library</span>
                </template>
                <el-tree
                    v-if="tree"
                    lazy
                    :empty-text="($t('setting.null'))"
                    style="max-width: 600px"
                    :highlight-current="true"
                    :props="defaultProps"
                    :load="loadNode"
                    @node-click="handleNodeClick"
                    :render-content="renderContent"
                ></el-tree>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-drawer>
      </div>
    </el-col>
    <el-col :md="20" :sm="body_sm" :xs="body_xs">
      <div class="popover" v-show="popover_visible">
        <p @click="login_out"><el-icon style="padding-left: 10px;padding-right: 4px;"><SwitchButton /></el-icon>退出登录</p>
      </div>
      <div class="body">
        <div class="item" v-for="(item,index) in slideData" :key="index">
          <div class="slide" @click="browse(item)">
<!--            <el-image class="label" :src="'/slide/Image/Label?path=' + encryptECB(item.fullName)" @error="handleError">
              <template #error>
                <div class="custom-error-content">
                  <el-icon color="#606266"><PictureFilled /></el-icon>
                </div>
              </template>
            </el-image>-->
            <el-image class="preview" :src="'/slide/Image/Preview?path=' + encryptECB(item.fullName)">
              <template #error>
                <div class="custom-error-content">
                  <el-icon color="#606266"><PictureFilled /></el-icon><p class="error-text">{{ $t('body.error') }}</p>
                </div>
              </template>
            </el-image>
          </div>
          <p><el-icon><Tickets /></el-icon><span class="slide_info">&nbsp;{{item.name}}</span></p>
          <p><el-icon><Timer /></el-icon><span class="slide_info">&nbsp;{{item.creationTime}}</span></p>
          <p @click="share(encryptECB(item.fullName))"><span class="slide_share"><el-icon><Share /></el-icon>&nbsp;{{ $t('body.share_click') }}</span></p>
        </div>
      </div>
    </el-col>
    <categoryManage></categoryManage>
    <share></share>
    <setting></setting>
  </el-row>
</template>

<script>
import upper from '../components/Header.vue'
import categoryManage from "@/components/dialogue/category manage.vue";
import share from "@/components/dialogue/share.vue";
import setting from "@/components/dialogue/setting.vue";
import { ElIcon } from 'element-plus';
import folder from '@/assets/img/dir.png'
import {encryptECB} from "@/aes/aes";
export default {
  data () {
    return {
      visible: true,
      isMobile: null,
      dialog: false,
      aside_sm: 2,
      body_sm: 22,
      aside_xs: 3,
      body_xs: 21,
      symbol: '',
      tree: true,
      label_show: true,
      treeData: [],
      path: 'E:\\slides',
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isFile'
      },
      slideData: [],
      popover_visible: false,
      timer: '',
    }
  },
  components: {
    upper,
    categoryManage,
    share,
    setting,
    ElIcon,
  },
  computed: {
  },
  methods: {
    encryptECB,
    refresh () {
      if (this.symbol != 0) {
        this.updateFiles()
      }
    },
    popover_show () {
      this.popover_visible = !this.popover_visible
    },
    login_out() {
      this.axios.post('/api/auth/logout').then(res => {
        this.$router.push('/')
      })
    },
    expand() {
      this.dialog = true
      this.aside_open = true
      /*this.aside_sm = 8
      this.body_sm = 16
      this.aside_xs = 12
      this.body_xs = 12*/
    },
    checkWindowSize() {
      this.visible = window.innerWidth <= 992
    },
    fold() {
      this.aside_open = false
      this.aside_sm = 3
      this.body_sm = 21
    },
    getDirectory(e) {
      this.symbol = e.length
      if (e.length != 0) {
        this.updateFiles()
        this.axios.get('/service/getFilePath?path=E:\\slides\\HE&type=1').then(res => {
          let data = JSON.parse(res.data)
          this.slideData = data.children
        })
      }
    },
    loadNode (node, resolve) {
      if (node.level === 0) {
        this.axios.get('/service/getFilePath?path=' + this.path + '&type=0').then(res => {
          this.treeData = JSON.parse(res.data)
          return resolve(this.treeData.children)
        })
      } else {
        this.axios.get('/service/getFilePath?path=' + node.data.fullName + '&type=0').then(res => {
          if (res.data.length === 0) {
            return resolve([])
          } else {
            let d = JSON.parse(res.data)
            let data = d.children
            return resolve(data)
          }
        })
      }
    },
    handleNodeClick (e) {
      this.axios.get('/service/getFilePath?path=' + e.fullName + '&type=1').then(res => {
        let data =  JSON.parse(res.data)
        this.slideData = data.children
      })
    },
    renderContent(h,{ node, data }) {
      return h('span', [
        h('img', {
          src: folder, // 使用本地图标资源
          style: { width: '20px', height: '20px', marginRight: '5px' },
        }),
        h('span', { style: { marginLeft: '5px' } }, data.name), // 标签内容
      ]);
    },
    browse (item) {
      window.open(`/slideViewer/#/viewer?url=` + encryptECB(item.fullName))
    },
    share (name) {
      let link = window.location.origin + '/slideViewer/#/viewer?url=' + name
      this.eventBus.emit('share', link)
    },
    updateFiles () {
      this.tree = false
      this.$nextTick(() => {
        this.tree = true
      })
    }
  },
  mounted() {
    /*this.axios.post('api/auth/category_list').then(res => {
      this.treeData = res.data.data
    })*/
    this.checkWindowSize()
    setTimeout(() => {
      this.visible = false
    }, 3000)
    this.getDirectory([1])
    this.eventBus.on('popover_show', this.popover_show)
  }
}
</script>

<style>
.aside{
  width: 100%;
  height: 94vh;
  background-color: #2c3b41;
}
.aside .el-collapse-item__content{
  height: 776px;
  overflow: auto;
}
.aside_mobile{
  width: 100%;
  height: 94vh;
  background-color: #2c3b41;
}
.aside_mobile .el-collapse-item__content{
  height: 80vh;
  overflow: auto;
}
.aside_mobile .el-drawer__header{
  margin: 0;
  padding: 10px 10px 5px 0px;
}
.folder_icon{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.folder_icon i{
  cursor: pointer;
}
.folder_icon:hover{
  background-color: rgba(255,255,255,0.2);
}
.body{
  background-color: #ecf0f5;
  width: 100%;
  height: 94vh;
  display: flex;
  padding: 20px 5px;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box;
  overflow: auto;
}
.popover{
  position: absolute;

  width: 150px;
  height: 50px;
  background-color: white;
  color: black;
  right: 0;
  top: 0;
  cursor: pointer;
}
.popover:hover{
  background-color: rgba(0,0,0,0.1);
}
.item{
  width: 18%;
  min-width: 270px;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 0px 0px 20px 20px;
}
.item p{
  margin: 4px 2px;
}
.slide{
  height: 100px;
  width: 180px;
  margin-bottom: 2px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3c8dbc;
  font-size: 1.5rem;
  text-align: center;
}
.slide .label{
  width: 75px;
  height: 75px;
}
.slide .preview{
  width: 150px;
  height: 75px;
}
.custom-error-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #f2f2f2;
}

.error-text {
  margin-top: 10px;
  color: #666;
  font-size: 14px;
}
.slide_info{
  font-size: 14px;
}
.slide_share{
  font-size: 14px;
  color: #3c8dbc;
  cursor: pointer;
  text-decoration: underline;
}
</style>
