import { createApp } from 'vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import mitt from 'mitt'
import 'element-plus/dist/index.css'
import '../src/assets/css/commonStyle.css'
import i18n from './language/index'
import App from './App.vue'

// 请求拦截
axios.interceptors.request.use(function (config) {
  // 发送请求数据之前做什么
  config.headers.Authorization = store.state.token
  return config
}, function (error) {
  // 对请求错误做什么
  return Promise.reject(error)
})
// 响应拦截
axios.interceptors.response.use(function (response) {
  // 对响应数据做什么
  return response
}, function (error) {
  // 对响应错误做什么
  return Promise.reject(error)
})

const app = createApp(App)
app.use(store)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.eventBus = mitt()
app.use(i18n)
app.use(router)
app.use(ElementPlus)
app.config.globalProperties.axios = axios;

app.mount('#app')
